/* eslint-disable react/no-danger */
import React from 'react';

import { Grid, Header, Accordion, Image as SemanticImage } from 'semantic-ui-react';

import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import _isEmpty from 'lodash/isEmpty';

import CustomerSignUpForm from '../components/ContactForm/CustomerSignUpForm';
import SEO from '../components/SEO';
import FAQ from '../data/content/coronavirus-faq';
import Layout from '../layouts/SiteLayout';
import { ObjectPropType } from '../utils/proptypes';

import FishermanLogo from '../data/logos/FishermanRedTextLogo.svg';

import '../css/pages/coronavirus-support.css';

const propTypes = {
  data: ObjectPropType.isRequired,
};

export default function PartnerSignUp({ data }) {
  const { fishermanPartner, partnerLogo } = data;
  const {
    name,
    header,
    submissionMessage,
    submissionButtonText,
    subheader,
    description,
    formFields,
    hideFaq = false,
    faq = [],
    referralSource,
  } = fishermanPartner;

  const headerDisplay = header || `Get a Modern Website`;
  const subheaderDisplay = subheader || `Free 1 Month Trial for ${name} Customers`;

  return (
    <Layout>
      <SEO title={`${name} - ${headerDisplay}`} description={subheaderDisplay} />
      <Grid className="partner-sign-up-container" stackable>
        <Grid.Column width={16} textAlign="center">
          <div className="partnership-logos">
            <div className="brand-logo">
              <Image fluid={partnerLogo.childImageSharp.fluid} />
            </div>
            <div className="brand-logo">
              <SemanticImage src={FishermanLogo} />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h1">{headerDisplay}</Header>
          <Header as="h3">{subheaderDisplay}</Header>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Grid.Column>
        <Grid.Column width={8}>
          <CustomerSignUpForm
            subjectTag={name}
            formFields={formFields}
            submissionMessage={submissionMessage}
            submissionButtonText={submissionButtonText}
            referralSource={referralSource}
          />
        </Grid.Column>
        {!hideFaq && (
          <Grid.Column width={16}>
            <div className="q-and-a">
              <Header as="h1" style={{ textAlign: 'center' }}>
                FAQ&apos;s
              </Header>
              <Accordion
                styled
                panels={(_isEmpty(faq) ? FAQ : faq).map(({ question, answer }) => ({
                  key: question,
                  title: {
                    content: <Header as="h4">{question}</Header>,
                  },
                  content: answer,
                }))}
              />
            </div>
          </Grid.Column>
        )}
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!, $logoFileName: String!) {
    fishermanPartner(slug: { eq: $slug }) {
      name
      description
      logoFileName
      submissionMessage
      submissionButtonText
      slug
      formFields
      header
      subheader
      hideFaq
      faq {
        question
        answer
      }
      referralSource
    }
    partnerLogo: file(name: { eq: $logoFileName }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

PartnerSignUp.propTypes = propTypes;
